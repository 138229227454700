import React from "react"
import { navigate } from "gatsby";

const Pagination = ({ location, current, total, perPage = 12, befCur = 2, aftCur = 2 }) => {

  const pages = [];
  const length = Math.ceil(total / perPage);
  for (let i = 0; i < length; i++) {
    pages.push(i);
  }

  const selectPage = (e, item) => {
    e.preventDefault();
    navigate(`${location.pathname}${item === 0 ? '' : `#${item + 1}`}`);
  }

  const min = Math.max(0, current - befCur);
  const max = Math.min(current + aftCur, pages.length - 1);

  return (
    <div className="d-flex justify-content-center pagination">
      {current > 0 && <><a href={`${location.pathname}#${current === 1 ? '' : current}`} className="pagination__arrow pagination__arrow--prev" onClick={(e) => selectPage(e, current - 1)} rel="prev"><span className="d-none">« Prev</span></a></>}
      {min > 0 && <a href={`${location.pathname}`} className="pagination__link" onClick={(e) => selectPage(e, 0)}>1</a>}
      {min > 1 && <span className="pagination__dots">…</span>}
      {pages.slice(min, max + 1).map(item => (
        <React.Fragment key={item}>
          {item === current ? (
            <>
              <span className="pagination__link pagination__link--current" aria-current="page">{item + 1}</span>
            </>
          ) : (
            <>
              <a href={`${location.pathname}${item === 0 ? '' : `#${item + 1}`}`} className="pagination__link" onClick={(e) => selectPage(e, item)}>{item + 1}</a>
            </>
          )}
        </React.Fragment>
      ))}
      {max < pages.length - 2 && <span className="pagination__dots">…</span>}
      {max < pages.length - 1 && <a href={`${location.pathname}#${pages.length}`} className="pagination__link" onClick={(e) => selectPage(e, pages.length - 1)}>{pages.length}</a>}
      {current < pages.length - 1 && <a href={`${location.pathname}#${current+2}`} className="pagination__arrow pagination__arrow--next" onClick={(e) => selectPage(e, current + 1)} rel="next"><span className="d-none">Next »</span></a>}
    </div>
  );
}

export default Pagination;
