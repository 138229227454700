import { useState, useEffect } from "react"
import { navigate } from 'gatsby'

const useHashPagination = (location, length) => {
  const [firstRender, setFirstRender] = useState(true);
  let currentPage = 0;
  if (!firstRender && location.hash) {
    const hashString = location.hash.substring(1);
    const hashPage = parseInt(hashString);
    if (!isNaN(hashPage) && hashPage > 0 && hashPage <= length) {
      currentPage = hashPage - 1;
    } else {
      navigate(`/${location.pathname}`);
    }
  }
  useEffect(() => {
    setFirstRender(false);
  }, []);
  return currentPage;
}

export default useHashPagination;