import React, { useMemo} from "react"
import { graphql } from 'gatsby'
import { Link } from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pagination from "../components/pagination"
import Pdf from '../images/ico-svg/file-pdf-light.svg'
import Clock from '../images/ico-svg/history-light.svg'
import { replaceHtml, slugPage } from '../utility/utils'
import useHashPagination from '../utility/useHashPagination'


const TemplatePaginaStorico = ({ pageContext: { breadcrumbs, titoloPadre, pagineSorelle, myPath }, data, location }) => {
  const { pagina, storico } = data;
  const { testo, prodotto_archiviato } = pagina;
  const testoMd = testo && testo.childMarkdownRemark.html;
  const testoHtml = useMemo(() => testoMd ? replaceHtml(testoMd) : null, [testoMd]);
  const resultsPerPage = 20;
  const prodotti = useMemo(() => prodotto_archiviato.sort((a, b) => a.nomeProdotto > b.nomeProdotto ? 1 : -1), [prodotto_archiviato]);
  const length = Math.ceil(prodotti.length / resultsPerPage);
  const currentPage = useHashPagination(location, length);

  return (
    <Layout data={data}>
      <SEO page={pagina} data={data} pagination={{ path: myPath, current: currentPage, length }}/>

      <div id="main" role="main">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prodotto template-storico">
                <div className="row">
                  <div className="col-12">
                    {/* Breadcrumb */}
                    <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
                      <ol className="breadcrumbs__list" itemType="http://schema.org/BreadcrumbList" itemScope="itemscope">
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level="1" itemProp="item">
                            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Homepage</span></Link>
                          </div>
                          <meta content="1" itemProp="position" />
                        </li>
                        {breadcrumbs.map((item, i) => (
                          <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement" key={i}>
                            <div aria-level={i + 2} itemProp="item">
                              <Link to={item.link} title={item.name} className="breadcrumbs__link" itemProp="url"><span itemProp="name">{item.name}</span></Link>
                            </div>
                            <meta content={i + 2} itemProp="position" />
                          </li>
                        ))}
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level={breadcrumbs.length + 2} itemProp="item">
                            <span itemProp="name">{titoloPadre}</span>
                          </div>
                          <meta content={breadcrumbs.length + 2} itemProp="position" />
                        </li>
                      </ol>
                    </div>

                    {/* Titolo  */}
                    <div className="d-flex flex-column flex-md-row">
                      <h1 className="prodotto__title">Storico prodotti: {titoloPadre}</h1>
                      <div className="ml-auto">
                        <Link to={`/prodotti/${slugPage(storico)}/`} title={storico.titolo} className="btn-link-icon mb-4"><span className="ico-svg"><Clock /></span>{storico.titolo}</Link>
                      </div>
                    </div>

                    {/* Testo */}
                    {testo && <div className="prodotto__text" dangerouslySetInnerHTML={{ __html: testoHtml }}></div>}

                    {/* Lista pagine sorelle */}
                    <div className="nav-tab">
                      <ul className="nav-tab__list d-md-flex">
                        {pagineSorelle.map((item, i) => (
                          <li key={i} className="nav-tab__item">
                            <Link to={item.path} title={item.titolo} className="nav-tab__link" activeClassName="active">
                              {item.titolo}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Lista prodotti */}
                    <div className="lista-prodotti">
                      <div className="lista-prodotti__head d-none d-sm-block">
                        <div className="row">
                          <div className="col-sm-5 col-lg-4">Nome prodotto</div>
                          <div className="col-sm-7 col-lg-8">
                            <div className="row">
                              <div className="col-sm-4 text-center">KID aggiornato</div>
                              <div className="col-sm-4 text-center">Documentazione precontrattuale</div>
                              <div className="col-sm-4 text-center">Condizioni contrattuali</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lista-prodotti__body">
                        {prodotti.slice(currentPage*resultsPerPage, (currentPage+1)*resultsPerPage).map((item, i) => (
                          <div className="lista-prodotti__row" key={i}>
                            <div className="row">
                              <div className="col-sm-5 col-lg-4">
                                {item.nomeProdotto}
                              </div>
                              <div className="col-sm-7 col-lg-8">
                                <div className="row h-100 text-sm-center">
                                  <div className="col-sm-4 d-md-flex align-items-center justify-content-center">
                                    <strong className="d-sm-none">KID aggiornato: </strong>
                                    <div>
                                    {item.schedaCommerciale && (
                                      <div className="my-2 text-left">
                                        <a href={item.schedaCommerciale.relativeUrl} title={item.schedaCommerciale.description} className="box-link__doc  d-inline-flex" target="_blank" rel="noopener noreferrer">
                                          <span className="ico-svg"><Pdf /></span>
                                          {item.schedaCommerciale.description && <span className="ml-2 d-inline-flex align-items-center">{item.schedaCommerciale.description}</span>}
                                        </a>
                                      </div>
                                    )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4 d-md-flex align-items-center justify-content-center">
                                    <strong className="d-sm-none">Documentazione precontrattuale: </strong>
                                    <div>
                                      {(item.fascicoloInformativo || item.documentazionePrecontrattuale) && (
                                        (item.fascicoloInformativo ? [item.fascicoloInformativo] : []).concat(item.documentazionePrecontrattuale || []).map((doc, i) => (
                                          <div key={i} className="my-2 text-left">
                                            <a href={doc.relativeUrl} title={doc.description}
                                              className="box-link__doc d-inline-flex" target="_blank" rel="noopener noreferrer">
                                              <span className="ico-svg"><Pdf /></span>
                                              {doc.description && <span className="ml-2 d-inline-flex align-items-center">{doc.description}</span>}
                                            </a>
                                          </div>
                                        ))
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4 d-md-flex align-items-center justify-content-center">
                                    <strong className="d-sm-none">Condizioni contrattuali: </strong>
                                    {item.condizioniContrattuali && (
                                      <a href={item.condizioniContrattuali.relativeUrl} title={item.condizioniContrattuali.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                                        <span className="ico-svg"><Pdf /></span>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {resultsPerPage < prodotti.length && <Pagination current={currentPage} total={prodotti.length} perPage={resultsPerPage} location={location}/>}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TemplatePaginaStorico;

export const query = graphql`
query PaginaStoricoQuery($contentful_id: String!){
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  pagina: contentfulLayoutPaginaInterna(contentful_id: {eq: $contentful_id}) {
    titolo
    seo {
      friendlyUrl
    }
    prodotto_archiviato {
      nomeProdotto
      schedaCommerciale {
        title
        description
        file {
          url
        }
        relativeUrl
      }
      fascicoloInformativo {
        title
        description
        file {
          url
        }
        relativeUrl
      }
      documentazionePrecontrattuale {
        title
        description
        file {
          url
        }
        relativeUrl
      }
      condizioniContrattuali {
        title
        description
        file {
          url
        }
        relativeUrl
      }
    }
  }
  storico: contentfulLayoutPaginaInterna(contentful_id: {eq: "5yrTXelY6CxH8wv9lpYcv2"}) {
    titolo
    seo {
      friendlyUrl
    }
  }
}`;